import auth from "api/auth";
import Loader from "components/global/Loader";
import userSignal from "signals/User.Signal";
import { useCallback, useEffect, useState } from "react";

const AuthWrapper = ({ children }) => {
  console.error("==================AuthWrapper=================");
  const [appIsLoading, setAppIsLoading] = useState(true);
  const loggedIn = sessionStorage.getItem("tmp-val");
  const authResult = sessionStorage.getItem("authResult");
  console.error("loggedIn: ", authResult);
  console.error("authResultp: ", authResult);
  console.error("is not temp: ", loggedIn == authResult);

  const { userId, session, cookieSession } = userSignal.value;
  const init = useCallback(async () => {
    await auth.fetchAndSetUserOnPageRefresh();
    setAppIsLoading(false);
  }, []);
  useEffect(() => {
    if (loggedIn == authResult) {
      if (!cookieSession) {
        setTimeout(() => {
          init();
        }, 500);
      }
    } else {
      setAppIsLoading(false);
    }
  }, []);
  if (appIsLoading) {
    return (
      <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 text-center">
        <Loader message="Loading..." />
      </div>
    );
  }

  return <div>{children}</div>;
};

export default AuthWrapper;
