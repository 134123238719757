/* eslint-disable no-throw-literal */
import Fetcher from "./fetcher";
import { encryptString, hashString } from "../utils/functions";
import userSignal from "signals/User.Signal";
import alertSignal from "signals/Alert.signal";
import loaderSignal from "signals/Loader.signal";
import { auth } from "utils/firebase";

const fetcher = new Fetcher({ log: true });

const login = async ({
  userId,
  password,
  secret,
  loginDate,
  cookieSession,
}) => {
  if (!userId) throw "User ID is missing";
  if (!password) throw "Password is missing";

  const psHash1 = await hashString({
    secret: userId.toUpperCase().trim(),
    value: secret,
  });

  const psHash2 = await hashString({
    secret: loginDate,
    value: psHash1,
  });

  const firstHash = await hashString({
    secret: process.env.REACT_APP_PASSWORD_SECRET ?? "",
    value: password,
  });

  const value = await hashString({
    secret: userId.toUpperCase().trim(),
    value: firstHash,
  });

  const hashedPass = await hashString({ secret, value });

  const psHashFinal = await hashString({
    secret: hashedPass,
    value: psHash2,
  });

  const req = await fetcher.post({
    path: "IQNeoSecLogin",
    body: {
      pLoginID: userId,
      pPW: hashedPass,
      pS: psHashFinal,
      pSess: cookieSession,
      pIQNR: 1,
    },
  });

  return req;
};

const getUserData = async ({ userId, session, cookieSession }) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";

  const req = await fetcher.post({
    path: "IQNeoFetchMember",
    headers: { Authorization: `Bearer ${session}` },
    body: { pLoginID: userId, pSessionID: session, pSess: cookieSession },
  });

  return req;
};

const getUserId = async (email, cookieSession) => {
  if (!email) throw "Email is missing";

  const req = await fetcher.post({
    path: "IQGetIDFromEmail",
    body: { pLoginemail: email, pSess: cookieSession },
  });

  return req;
};

const getTempSession = async (userId, cookieSession) => {
  if (!userId) throw "User ID is missing ";

  const req = await fetcher.post({
    path: "IQSetTempSession",
    body: { pLoginID: userId, pSess: cookieSession },
  });

  return req;
};

const getAllQuestions = async (cookieSession) => {
  console.error("cookieSession: ", cookieSession);
  const req = await fetcher.post({
    body: { pSess: cookieSession },
    path: `IQFetchQuestionsList`,
  });

  return req;
};

const getAnsweredQuestions = async ({ userId, session, cookieSession }) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";

  const req = await fetcher.post({
    path: "IQGetSecQuestions",
    headers: { Authorization: `Bearer ${session}` },
    body: { pLoginID: userId, pSessionID: session, pSess: cookieSession },
  });

  return req;
};

const validateAnswer = async ({
  userId,
  session,
  qNum,
  qAnswer,
  cookieSession,
}) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";
  if (!qNum) throw "Question number is missing";
  if (!qAnswer) throw "Answer is missing";
  const authResult = sessionStorage.getItem("authResult");
  const [value, secret] = await Promise.all([
    hashString({
      value: qAnswer.toUpperCase(),
      secret: process.env.REACT_APP_QUESTION_SECRET ?? "",
    }),
    authResult,
  ]);

  const pQAns = await hashString({ value, secret });

  const req = await fetcher.post({
    path: "IQValidateSecQuestion",
    headers: { Authorization: `Bearer ${session}` },
    body: {
      pLoginID: userId,
      pQNum: qNum,
      pQAns,
      pSessionID: session,
      pSess: cookieSession,
    },
  });
  return req;
};

const getResetMethods = async ({ userId, session, cookieSession }) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";

  const req = await fetcher.post({
    path: "IQGetResetCodeMethods",
    headers: { Authorization: `Bearer ${session}` },
    body: { pLoginID: userId, pSessionID: session, pSess: cookieSession },
  });

  return req;
};

const sendResetCode = async ({ userId, session, method, cookieSession }) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";
  if (!method) throw "Method is missing";

  const req = await fetcher.post({
    path: "IQSendResetCode",
    headers: { Authorization: `Bearer ${session}` },
    body: {
      pLoginID: userId,
      pMethod: method,
      pSessionID: session,
      pSess: cookieSession,
    },
  });

  return req;
};

const verifyCode = async ({ userId, session, code, cookieSession }) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";
  if (!code) throw "Code is missing";

  const req = await fetcher.post({
    path: "IQVerifyResetCode",
    headers: { Authorization: `Bearer ${session}` },
    body: {
      pLoginID: userId,
      pResetCode: code,
      pSessionID: session,
      pSess: cookieSession,
    },
  });

  return req;
};

const getPasswordRequirements = async () => {
  const req = await fetcher.get({ path: "IQFetchPasswordRequirments" });

  return req;
};

const resetPassword = async ({
  userId,
  newPassword,
  session,
  cookieSession,
}) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired ";
  if (!newPassword) throw "Password is missing ";
  userId = userId.toUpperCase().trim();
  newPassword = newPassword.trim();
  const authResult = sessionStorage.getItem("authResult");
  const pNewPW = await encryptString(newPassword, authResult);

  const req = fetcher.post({
    path: "IQChangePasswordNoCurrent",
    headers: { Authorization: `Bearer ${session}` },
    body: {
      pLoginID: userId,
      pNewPW,
      pSessionID: session,
      pSess: cookieSession,
    },
  });

  return req;
};

const updatePassword = async ({
  userId,
  newPassword,
  session,
  currentPassword,
  cookieSession,
}) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired ";
  if (!newPassword) throw "Password is missing ";
  if (!currentPassword) throw "Current password is missing ";
  const authResult = sessionStorage.getItem("authResult");
  const [secret, firstRound] = await Promise.all([
    authResult,
    hashString({
      secret: process.env.REACT_APP_PASSWORD_SECRET ?? "",
      value: currentPassword,
    }),
  ]);

  const value = await hashString({
    secret: userId.toUpperCase().trim(),
    value: firstRound,
  });

  const [pCurrPW, pNewPW] = await Promise.all([
    hashString({ secret, value }),
    encryptString(newPassword, authResult),
  ]);

  const req = fetcher.post({
    path: "IQChangePassword",
    headers: { Authorization: `Bearer ${session}` },
    body: {
      pLoginID: userId,
      pNewPW,
      pCurrPW,
      pSessionID: session,
      pSess: cookieSession,
    },
  });
  return req;
};

const getLogo = async ({ userId, session }) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";

  const req = fetcher.post({
    path: "IQFetchCULogo",
    headers: { Authorization: `Bearer ${session}` },
    body: { pLoginID: userId, pSessionID: session },
  });

  return req;
};

const setQuestions = async ({
  session,
  userId,
  qNum1,
  qNum2,
  qNum3,
  qAns1,
  qAns2,
  qAns3,
  cookieSession,
}) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired";
  if (!qNum1 || !qNum2 || !qNum3) {
    throw "Please provide question numbers for all questions.";
  }
  if (!qAns1 || !qAns2 || !qAns3)
    throw "Please provide answers for all questions.";

  const secret = process.env.REACT_APP_QUESTION_SECRET ?? "";
  const [pQAns1, pQAns2, pQAns3] = await Promise.all([
    hashString({ value: qAns1.toUpperCase(), secret }),
    hashString({ value: qAns2.toUpperCase(), secret }),
    hashString({ value: qAns3.toUpperCase(), secret }),
  ]);

  const req = fetcher.post({
    path: "IQSetSecQuestions",
    headers: { Authorization: `Bearer ${session}` },
    body: {
      pLoginID: userId,
      pQNum1: qNum1,
      pQNum2: qNum2,
      pQNum3: qNum3,
      pQAns1,
      pQAns2,
      pQAns3,
      pSessionID: session,
      pSess: cookieSession,
    },
  });

  return req;
};

const resetPasswordFlag = async ({ session, userId }) => {
  if (!userId) throw "User ID is missing";
  if (!session) throw "Session is missing or expired ";

  const req = fetcher.post({
    path: "IQResetChangePassWordFlag",
    headers: { Authorization: `Bearer ${session}` },
    body: { pLoginID: userId, pSessionID: session },
  });

  return req;
};

const getFaq = async ({ cookieSession }) => {
  const req = await fetcher.get({
    path: `IQFetchFAQList?pResolution=1&pSess=${cookieSession}`,
  });
  return req;
};

const authentication1 = async () => {
  const req = await fetcher.get({ path: "IQNeoAuthReq" });

  return req;
};

const authentication2 = async ({ session, date }, history) => {
  try {
    const [userVal, passVal] = await Promise.all([
      hashString({
        value: date,
        secret: process.env.REACT_APP_B_USER_SECRET ?? "",
      }),
      hashString({
        value: date,
        secret: process.env.REACT_APP_B_PASSWORD_SECRET ?? "",
      }),
    ]);
    const [pUser, pPW] = await Promise.all([
      hashString({ value: session, secret: userVal }),
      hashString({ value: session, secret: passVal }),
    ]);

    const req = await fetcher.post({
      path: "IQNeoAuthReqB",
      body: { pUser, pPW, pSess: session },
    });
    // const refreshTimeout = setTimeout(async () => {
    //   logout();
    //   alertSignal.update({
    //     type: 'notification',
    //     message: 'Your session has expired. Please login again.',
    //   });
    //   clearTimeout(refreshTimeout);
    // }, 300000);
    return req;
  } catch (error) {
    console.error(error);
  }
};

export const logout = () => {
  sessionStorage.removeItem("userId");
  sessionStorage.removeItem("session");
  sessionStorage.removeItem("cookieSession");
  sessionStorage.removeItem("tmp-val");
  sessionStorage.removeItem("authResult");
  setTimeout(() => {
    userSignal.reset();
  }, 500);
};

const fetchAndSetUserOnPageRefresh = async () => {
  const userId = sessionStorage.getItem("userId");
  const session = sessionStorage.getItem("session");
  const cookieSession = sessionStorage.getItem("cookieSession");

  if (userId && session && cookieSession) {
    try {
      const { IQResult: userData } = await getUserData({
        session,
        userId,
        cookieSession,
      });

      userSignal.update({
        ...userData,
        session,
        cookieSession,
        userId,
        isSignedIn: true,
      });
    } catch (error) {
      alertSignal.update({
        type: "notification",
        message: "Your session has expired. Please login again.",
      });

      logout();
    }
  } else {
    userSignal.update({
      userData: null,
      session: null,
      userId: null,
      isSignedIn: false,
    });
  }
};

const getStatementsList = async ({ userId, session, cookieSession }) => {
  if (!userId) throw new Error("User ID is missing");
  if (!session || !cookieSession)
    throw new Error("Session is missing or expired");

  const req = await fetcher.post({
    path: "IQGetStmtList",
    headers: { Authorization: `Bearer ${session}` },
    body: { pLoginID: userId, pSessionID: session, pSess: cookieSession },
  });

  return req;
};

export default {
  login,
  getAllQuestions,
  getUserData,
  getAnsweredQuestions,
  getUserId,
  getTempSession,
  validateAnswer,
  getResetMethods,
  sendResetCode,
  verifyCode,
  getPasswordRequirements,
  resetPassword,
  getLogo,
  setQuestions,
  updatePassword,
  resetPasswordFlag,
  getFaq,
  authentication1,
  authentication2,
  fetchAndSetUserOnPageRefresh,
  logout,
  getStatementsList,
};
